import React from 'react';
import Icon from '../icon';
import PropTypes from 'prop-types';

const FieldSearch = ({
  id,
  label,
  value,
  disabled,
  placeholder,
  onChange,
  inputClass,
  handler,
  suffix = null
}) => (
  <fieldset
    className={'fieldset--no-border'}
    disabled={disabled
      ? 'disabled'
      : false}>
    <form onSubmit={event => {
      event.preventDefault();
      handler();
    }} >
      <div className="field-text">
        <label className="field-text--label visually-hidden"
          htmlFor={id}>{label}</label>
        <div className="field-text--input client--search">
          <input type="text"
            className={inputClass}
            id={id}
            name={id}
            placeholder={placeholder}
            value={value} onChange={onChange}/>
          {suffix && <span className="field-text--suffix">{suffix}</span>}
          <button
            type={"submit"}
            className={`button button__label button--icon-right button--primary${disabled
              ? ' button--is-disabled'
              : ''}`}>
            Search
            <Icon icon="search" theme="primary"/>
          </button>
        </div>
      </div>
    </form>
  </fieldset>
);

FieldSearch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  inputClass: PropTypes.string,
  handler: PropTypes.func,
  suffix: PropTypes.element
};

export default FieldSearch;
