import React, { useContext } from 'react';
import ClientsAPIContext from '../services/clients';
import PropTypes from 'prop-types';
import Icon from './icon';

const Pager = ({ pagerLinks }) => {
  const api = useContext(ClientsAPIContext);
  const onClick = url => {
    api.load({ url });
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  };

  return (
    <div className={'pager'}>
      <button
        className={`button button--previous button--secondary ${pagerLinks.prev
          ? ''
          : 'button--is-disabled'}`}
        onClick={() => pagerLinks.prev
          ? onClick(pagerLinks.prev)
          : null}
        disabled={!pagerLinks.prev}
      >
        <Icon icon={'arrow'}/>
        Prev
      </button>

      <button
        className={`button button--next button--secondary ${pagerLinks.next
          ? ''
          : 'button--is-disabled'}`}
        onClick={() => pagerLinks.next
          ? onClick(pagerLinks.next)
          : null}
        disabled={!pagerLinks.next}
      >
        Next
        <Icon icon={'arrow'}/>
      </button>
    </div>
  );
};

Pager.propTypes = {
  pagerLinks: PropTypes.object
};

export default Pager;
