import React, { useContext, useEffect, useRef, useState } from 'react';

import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import ClientsAPIContext, { ClientsAPIProvider } from '../services/clients';

import Card from '../components/card';
import ClientName from '../components/client/name';
import ClientStatus from '../components/client/status';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import FieldSearch from '../components/field/search';
import LoadingIndicator from '../components/LoadingIndicator';
import Pager from '../components/pager';
import Page from '../components/page';
import TanStackTable from '../components/TanStackTable';

const columns = [
  {
    Header: 'Client',
    accessor: 'name',
    sortable: false,
    headerClassName: 'rt-header_left',
    Cell: props => (
      <ClientName
        name={props.value}
        folder={props.original.folder}
        id={props.original.id}
      />
    )
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortable: false,
    maxWidth: 300,
    headerClassName: 'rt-header_left',
    className: 'show-overflow',
    Cell: props => (
      <ClientStatus
        appointments={props.original.appointments}
        isInfoComplete={props.original.infoComplete === 1}
        payment={props.original.payments}
      />
    )
  }
];

const getColumns = data => [
  {
    header: 'Client',
    accessorKey: 'name',
    enableSorting: false,
    meta: {
      headerProps: {
        className: 'rt-header_left',
      }
    },
    cell: ({getValue, row}) => (
      <ClientName
        name={getValue()}
        folder={row.original.folder}
        id={row.original.id}
      />
    )
  },
  {
    header: 'Status',
    accessorKey: 'status',
    enableSorting: false,
    maxSize: 300,
    meta: {
      cellProps: {
        className: 'show-overflow',
      },
      headerProps: {
        className: 'rt-header_left',
      }
    },
    cell: ({row}) => (
      <ClientStatus
        appointments={row.original.appointments}
        isInfoComplete={row.original.infoComplete === 1}
        payment={row.original.payments}
      />
    )
  }
];

const ClientsPage = () => {
  const api = useContext(ClientsAPIContext);
  const { isPending, isReloading, data, cacheData, pagerLinks } = api;

  // Similar to componentDidMount
  useEffect(() => {
    api.load();
  }, []);

  const clientsTable = useRef();

  // State: Expanded
  const [
    expanded,
    setExpanded
  ] = useState(false);

  // State: SearchInput
  const [
    searchInput,
    setSearchInput
  ] = useState('');

  const toggleExpanded = () => setExpanded(prevExpanded => !prevExpanded);

  // State: Filter
  const [
    filter,
    setFilter
  ] = useState({
    from: null,
    to: null,
    locations: []
  });

  const setDates = date => {
    setFilter(Object.assign(filter, date));
  };

  const toggleChecked = event => {
    const locations = [...filter.locations];
    const index = locations.indexOf(event.target.value);

    // eslint-disable-next-line no-magic-numbers
    if (index === -1) {
      locations.push(event.target.value);
    } else {
      // eslint-disable-next-line no-magic-numbers
      locations.splice(index, 1);
    }

    setFilter(Object.assign({}, filter, { locations }));
  };

  const toggleAll = values => {
    const locations = values;

    setFilter(Object.assign({}, filter, { locations }));
  };

  const searchHandler = () => {
    const options = {
      url: api.data.links.self.split("?")[0],
      params: {
        search: encodeURIComponent(searchInput)
      }
    };

    api.load(options);
  };

  const { locations, from, to } = filter;

  const table = useReactTable({
    data: isPending
      ? cacheData.clients
      : data.clients ? data.clients : [],
    columns: getColumns(data),
    defaultColumn: {
      size: 100,
      enableSorting: false,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="container container--2-col">
        <div className="container--main">
          <FieldSearch disabled={isReloading || isPending} label="Search by client Name or Folder Number"
            placeholder="Search by client Name or Folder #"
            onChange={input => setSearchInput(input.target.value)}
            handler={searchHandler}>
          </FieldSearch>
        </div>
        <div className="container--aside">
          {/* <FilterButton title="Filter Results" icon1="filter" icon2="arrowCircle" expandFilters={toggleExpanded} toggled={expanded}></FilterButton>*/}
        </div>
      </div>
      <div className="container container--expandable" data-expand={expanded}>
        <div className="container--main">
          <Card>
            <LoadingIndicator show={isReloading || isPending} passThru={true}>
              <TanStackTable
                table={table}
                ref={clientsTable}
                />
            </LoadingIndicator>
          </Card>
          <Pager pagerLinks={pagerLinks} />
        </div>
        <aside className="container--aside container--filters">
          {/* <FilterPanel title="Filter Results" >*/}
          {/*  <FilterPane title="Date">*/}
          {/*    <DateRange from={from} to={to} onChange={setDates} />*/}
          {/*  </FilterPane>*/}
          {/*  <LocationsFilter values={locations} onChange={toggleChecked} all={toggleAll} />*/}
          {/* </FilterPanel>*/}
        </aside>
      </div>
    </>
  );
};

const Clients = () => (
  <Page>
    <DashboardLayout>
      <ClientsAPIProvider>
        <ClientsPage/>
      </ClientsAPIProvider>
    </DashboardLayout>
  </Page>
);

export default Clients;
