/**
 *  API: clients
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useCache, useLoads} from 'react-loads';
import { request } from '../lib/request';
import get from 'lodash/get';

const ROUTE = '/api/clients';

export const clientsLoad = (options = {}) => {
  const url = options.url || ROUTE;

  return request(url, options);
};

// Create a Context
const ClientsAPIContext = React.createContext();

/**
 * Gets the data from a API response.
 * @param {Object} response
 *  The response returned from an API request
 * @returns {Object}
 *  The raw data from the API response
 */
const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data;
  }

  return {
    clients: [],
    links: []
  };
};

/**
 * Creates an array of objects with key value pairs for use with form elements.
 * @param {Object} response
 *   The response returned from an API request
 * @returns {Array}
 *   An array of objects with key and label properties
 */
const getOptionsFromResponse = response => {
  const data = getDataFromResponse(response);

  // Ensure data is an array.
  const values = Array.isArray(data)
    ? data
    : Object.values(data);

  return values.map(item => ({
    id: item.id,
    label: item.label
  }));
};

export const ClientsAPIProvider = ({ children }) => {
  const loader = useLoads('clients', clientsLoad, {
    defer: true
  });
  const cacheLoader = useCache('clients');

  const [
    pagerLinks,
    setPagerLinks
  ] = useState({
    prev: null,
    next: null
  });

  useEffect(() => {
    const next = get(loader, 'response.data.links.next');
    const prev = get(loader, 'response.data.links.prev');

    setPagerLinks({
      next,
      prev
    });
  }, [loader.response]);

  // Raw response data
  const data = getDataFromResponse(loader.response);
  const cacheData = getDataFromResponse(get(cacheLoader,'response'));

  // Location key / value pa
  const options = getOptionsFromResponse(loader.response);

  return (
    <ClientsAPIContext.Provider value={Object.assign({ pagerLinks }, loader,{
      data,
      cacheData,
      options
    })}>
      {children}
    </ClientsAPIContext.Provider>
  );
};

ClientsAPIProvider.propTypes = {
  children: PropTypes.element
};

export default ClientsAPIContext;
